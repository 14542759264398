<template>
<v-layout>
    <v-app-bar
    absolute
    color="#fff"
  >
  
    <img src="@/assets/logo-pharmacall.jpg" height="60" />

    <v-spacer></v-spacer>
    <p class="header__points" v-if="authService.isLogged()">
      <v-icon class="header__points-icon">mdi-star</v-icon>
      {{ puntos }} puntos
    </p>
    <p class="header__text" v-if="authService.isLogged()">
      Hola <span> {{ cliente }} </span>
    </p>
    <p class="header__signout" @click="authService.logout()" v-if="authService.isLogged()">
      Desconectar
      <v-icon class="header__signout-icon">mdi-close</v-icon>
    </p>

    <v-btn
      class="ma-2"
      outlined
      color="primary"
      to="/login"
      v-if="!authService.isLogged()"
    >
      Inicia sesión
    </v-btn>

    <v-btn
      class="ma-2"
      outlined
      color="primary"
      to="/registro"
      v-if="!authService.isLogged()"
    >
      Regístrate
    </v-btn>
  </v-app-bar>
  <div class="home">
        <div class="home__banner">
            <p class="home__banner__text">
                Bienvenida/o  
                <span class="home__banner__text-client">{{ cliente }}</span>
                <br>
                <span class="home__banner__text-subtext">canjea tus puntos en cupones de amazon de 20€</span>
            </p>
        </div>
        <div v-if="seccion === 'home'" class="d-flex flex-sm-row flex-column justify-center" style="background-color:#e1e1e1;">
            <div class="home__points col-12 col-sm-5 col-md-4 d-flex">
                <div class="ma-auto">
                    <v-icon class="home__points-icon" style="color:white;">mdi-star</v-icon>
                    <p class="home__points-text">Tienes</p>
                    <p class="home__points-points">{{ puntos }}</p>
                    <p class="home__points-subtext">PUNTOS</p>
                </div>
            </div>
            <div class="home__exchange col-12 col-sm-5 col-md-4 d-flex">
                <div class="ma-auto">
                    <p class="home__exchange-title">Canjear puntos</p>
                    <p class="home__exchange-subtitle">Cada vez que pulses en canjear, <b>cambiarás 20 puntos por un cupón de Amazon por valor de 20€</b>. ¡Disfrútalos!</p>
                    <v-btn
                        depressed
                        tile
                        color="primary"
                        class="home__exchange-button"
                        :disabled="puntos < 20 || loading"
                        @click="generarCupon"
                        >
                        Canjear cupón
                    </v-btn>
                </div>                
            </div>
        </div>
        <div v-if="seccion === 'home' && screen === 1" class="d-flex flex-sm-row flex-column justify-center" style="background-color:#e1e1e1;">
            <div class="home__coupon col-12 col-sm-12 col-md-12 d-flex">
                <div class="ma-auto">
                    <v-icon class="home__coupon-icon">mdi-cloud-download</v-icon>
                    <p class="home__coupon-text">Canjeando cupón ... </p>
                    <p class="home__coupon-coupon">  </p>
                    <p class="home__coupon-subtext"> </p>
                </div>
            </div>
        </div>        
        <div v-if="seccion === 'home' && screen === 2" class="d-flex flex-sm-row flex-column justify-center" style="background-color:#e1e1e1;">
            <div class="home__coupon col-12 col-sm-12 col-md-12 d-flex">
                <div class="ma-auto">
                    <v-icon class="home__coupon-icon">mdi-cash-multiple</v-icon>
                    <p class="home__coupon-text">Se ha canjeado un nuevo cupón</p>
                    <p class="home__coupon-coupon"> {{ cupon }} </p>
                    <p class="home__coupon-subtext">Copia y usa este cupón en Amazon. Adema´s te lo hmos enviado a tu dirección de correo electrónico para guaradr una copia</p>
                </div>
            </div>
        </div>    
        <div v-if="seccion === 'home' && screen === 3" class="d-flex flex-sm-row flex-column justify-center" style="background-color:#e1e1e1;">
            <div class="home__coupon col-12 col-sm-12 col-md-12 d-flex">
                <div class="ma-auto">
                    <v-icon class="home__coupon-icon">mdi-alert-circle</v-icon>
                    <p class="home__coupon-text">No se ha podido canjear un nuevo cupón</p>
                    <p class="home__coupon-coupon">  </p>
                    <p class="home__coupon-subtext">Por favor vuelve a intentarlo o ponte en contacto con nosotros en <a href="mailto:aaaa@aaa.com" target="new"> aaa@aaa.com </a></p>
                </div>
            </div>
        </div>                    
        <div class="home__personal">
            <h2 class="home__personal-title">Mis datos personales</h2>
            <div class="home__personal__cards justify-center">
                <div class="home__personal__cards__card" @click="sub_seccion != 'password' ? sub_seccion = 'password' : sub_seccion = ''">
                    <v-icon class="home__personal__cards__card-icon">mdi-account-outline</v-icon>
                    <p class="home__personal__cards__card-text">Cambiar contraseña</p>
                </div>
                <div class="home__personal__cards__card" @click="sub_seccion != 'cupones' ? sub_seccion = 'cupones' : sub_seccion = ''">
                    <v-icon class="home__personal__cards__card-icon">mdi-check-all</v-icon>
                    <p class="home__personal__cards__card-text">Mis cupones</p>
                </div>
                <!--
                <div class="home__personal__cards__card" @click="sub_seccion != 'info' ? sub_seccion = 'info' : sub_seccion = ''">
                    <v-icon class="home__personal__cards__card-icon">mdi-email-outline</v-icon>
                    <p class="home__personal__cards__card-text">Solicitar información</p>
                </div>
                -->
            </div>
        </div>
        <v-expand-transition>
        <div v-if="sub_seccion === 'password'" class="d-flex flex-sm-row flex-column justify-center" style="background-color:white;">
            <div class="home__password col-12 col-sm-12 col-md-12 d-flex">
                <div class="ma-auto" style="text-align:center;width:100%;">   
                    <v-card class="ma-auto" style="width:80%;padding:20px;">         
                        <form>    
                            <v-text-field
                                    label="Contraseña actual"
                                    outlined
                                    v-model="password"
                                    :error-messages="errors"
                                    type="password"
                                    dense
                                    autocomplete
                                    required
                                ></v-text-field>
                            <v-text-field
                                    label="Nueva contraseña"
                                    outlined
                                    v-model="npassword"
                                    :error-messages="errors"
                                    type="password"
                                    dense
                                    autocomplete
                                    required
                                ></v-text-field>  
                            <v-text-field
                                    label="Repita la nueva contraseña"
                                    outlined
                                    v-model="rpassword"
                                    :error-messages="errors"
                                    type="password"
                                    dense
                                    autocomplete
                                    required
                                ></v-text-field>
                        </form>
                        <div class="ma-auto">          
                            <v-btn
                                depressed
                                tile
                                color="primary"
                                class="home__exchange-button"
                                :disabled="change"
                                @click="setPassword"
                                >
                                Cambiar contraseña
                            </v-btn>                               
                        </div>                                                                     
                    </v-card>
                </div>
            </div>
        </div>
        </v-expand-transition>
        <v-expand-transition>
        <div v-if="sub_seccion === 'cupones'" class="home__cupones d-flex flex-sm-row flex-column justify-center" style="background-color:white;margin-bottom:50px;">
            <div class="home__password col-12 col-sm-12 col-md-12 d-flex">
                <div class="ma-auto" style="text-align:center;width:100%;">   
                    <v-card class="ma-auto" style="width:80%;padding:20px;">   
                        <v-data-table
                            :headers="[
                                { text: 'Código del cupon', value: 'code', align: 'center', sortable: true },
                                { text: 'Fecha de canjeo', value: 'date', align: 'center', sortable: true },
                                { text: 'Valor del cupon', value: 'amount', align: 'center', sortable: true },
                            ]"
                            :items="cupones"                  
                            item-key="name"
                            class="elevation-1"                  
                            loading-text="Cargando cupones ..."
                        >
                        <template v-slot:[`item.date`]="{ item }">
                            {{ formatDate(item.date) }}
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ item.amount }}€
                        </template>
                        </v-data-table>
                    </v-card>
                </div>
            </div>
        </div>
        </v-expand-transition>
        <v-expand-transition>
        <div v-if="sub_seccion === 'info'" class="d-flex flex-sm-row flex-column justify-center" style="background-color:white;">
<div class="home__password col-12 col-sm-12 col-md-12 d-flex">
                <div class="ma-auto" style="text-align:center;width:100%;">   
                    
                    <v-card class="ma-auto" style="width:80%;padding:20px;">         
                        <form>    
                            <v-text-field
                                    label="Nombre"
                                    outlined
                                    v-model="nombre"
                                    :error-messages="errors"
                                    type="text"
                                    dense
                                    required
                                ></v-text-field>
                            <v-text-field
                                    label="Email"
                                    outlined
                                    v-model="email"
                                    :error-messages="errors"
                                    type="email"
                                    dense
                                    required
                                ></v-text-field>  
                            <v-text-field
                                    label="Asunto"
                                    outlined
                                    v-model="asunto"
                                    :error-messages="errors"
                                    type="password"
                                    dense
                                    autocomplete
                                    required
                                ></v-text-field>
                        </form>
                        <div class="ma-auto">          
                            <v-btn
                                depressed
                                tile
                                color="primary"
                                class="home__exchange-button"
                                :disabled="false"
                                @click="setPassword"
                                >
                                Enviar contacto
                            </v-btn>                               
                        </div>                                                                     
                    </v-card>
                    
                </div>
            </div>            
        </div>           
        </v-expand-transition>       
        <div class="home__info">
            <h2 class="home__info-title">El socio perfecto entre LABORATORIO y FARMACIA</h2>
            <p class="home__info-subtitle">Definir, desarrollar y vender soluciones innovadoras que creen valor en la relación entre las empresas farmacéuticas y las farmacias para el éxito de ambas.</p>
            <div class="home__info__cards justify-center col-12">
                <div href="https://www.pharma-call.com/servicios-a-la-industria/" target="_blank" class="home__info__cards__card col-12 col-xs-12 col-sm-4  d-flex">
                    <div style="position:relative">
                    <img src="@/assets/servicios-banner.jpg">
                    <div class="home__info__cards__card-overlay"></div>
                    <p class="home__info__cards__card-text">SERVICIOS</p>
                    </div>
                </div>
                <div href="https://www.pharma-call.com/servicios-a-la-industria/" target="_blank" class="home__info__cards__card col-12 col-xs-12 col-sm-4  d-flex">
                    <div style="position:relative">
                    <img src="@/assets/areas-banner.jpg">
                    <div class="home__info__cards__card-overlay"></div>
                    <p class="home__info__cards__card-text">ÁREAS DE ACCIÓN</p>
                    </div>
                </div>
                <div href="https://www.pharma-call.com/servicios-a-la-industria/" target="_blank" class="home__info__cards__card col-12 col-xs-12 col-sm-4  d-flex">
                    <div style="position:relative">
                    <img src="@/assets/diferencia3.jpg">
                    <div class="home__info__cards__card-overlay"></div>
                    <p class="home__info__cards__card-text">LO QUE NOS DIFERENCIA</p>
                    </div>
                </div>       
                <!--                         
                <a href="https://www.pharma-call.com/servicios-a-la-industria/#reas-de-accin" target="_blank" class="home__info__cards__card col-12 col-sm-4">
                    <p class="home__info__cards__card-text">N</p>
                </a>
                <a href="https://www.pharma-call.com/pharma-call/#lo-que-nos-diferencia" target="_blank" class="home__info__cards__card col-12 col-sm-4">
                    <p class="home__info__cards__card-text"></p>
                </a>
                -->
            </div>
        </div>
        <div class="home__copyright">
            <div class="home__copyright__container">
                <span class="copyright">© Copyright - Pharma Call<div style="float:right;"> 
                    <router-link to="/condiciones-de-la-promocion" style="color:white;text-decoration:none;">Condiciones de la promoción</router-link> &nbsp;|&nbsp;
                    <a style="color:white;text-decoration:none;" target="new" href="https://www.pharma-call.com/politica-privacidad/">Política de privacidad</a> &nbsp;|&nbsp; <a target="new" style="color:white;text-decoration:none;" href="https://www.pharma-call.com/politica-de-cookies/">Política de cookies</a> </div></span>
            </div>
        </div>            
  </div>
  <v-snackbar
        v-model="showChanged"
        color="success"
        :top="true"
        :center="true"
      >
        <v-row justify="center" style="padding:10px">
          <div style="margin-top:10px;margin-left:15px;">
            Contraseña cambiada con éxito
          </div>
          <v-spacer />
          <v-btn
            text
            @click="showChanged = false"
          >
            Aceptar
          </v-btn>
          <v-spacer />
        </v-row>
      </v-snackbar>
      <v-snackbar
        v-model="showError"
        color="error"
        :top="true"
        :center="true"
      >
        <v-row justify="center" style="padding:10px">
          <div style="margin-top:10px;margin-left:15px;">
            ERROR: No se ha podido cambiar la contraseña. Recuerda que debe tener una longitud mínima de 8 e incluir una mayuscula y un simbolo
          </div>
          <v-spacer />
          <v-btn
            text
            @click="showError = false"
          >
            Aceptar
          </v-btn>
          <v-spacer />
        </v-row>
      </v-snackbar>  
</v-layout>
</template>

<script>
import httpService from '@/services/httpService'
import AuthService from '@/services/authService'
import moment from 'moment'

export default {
  name: 'Home',
  data () {
      return {
        authService: AuthService,
        change: true,
        loading: false,
        screen: 0,
        seccion: 'home',
        cliente: 'Farmacia Pepito',
        puntos: 0,
        cupon: 'AAAA-BBBB-CCCC-DDDD',
        generate: false,
        sub_seccion: '',
        errors: [],
        cupones: [],
        password: '',
        npassword: '',
        rpassword: '',
        showChanged: false,
        showError: false,
        message: null,
        nombre: '',
        email: '',
        asunto: '',
        mensaje: ''
      }
  },
  watch: {
      rpassword(pass) {
          this.change = this.checkPassword(pass)
      }

  },
  mounted () {
    this.getProfile()
  },
  methods: {
    formatDate(item) {
        return moment(item).format('DD/MM/yyyy HH:mm');
    },
    generarCupon() {        
        this.screen = 1
        this.loading = true
        httpService.get('/profile/cupon')
        .then( res => {
            if ( res.data && res.data.profile ) {
                AuthService.setUser(res.data.profile)                
                this.cupones = res.data.profile.coupons
                this.puntos = res.data.profile.available.toFixed(2);
            }
            if ( res.data && res.data.cupon ) {                
                this.cupon = res.data.cupon.code
                this.screen = 2                
            } else {
                this.screen = 3
            }
            this.loading = false
        })
        .catch( err => {
            console.log(err)
            this.loading = false
        })
    },
    async getProfile() {
        httpService.get('/profile')
        .then( res => {
            if ( res.data && res.data.profile && res.data.profile.role === 'user' ) {
                console.log(res.data.profile)
                const profile = res.data.profile
                AuthService.setUser(profile)
                this.cliente = profile.name
                this.puntos = profile.available.toFixed(2);
                this.cupones = profile.coupons
            } else {                 
                this.$router.push('/admin');            
            }
        })
        .catch( err => {
            console.log(err)
        })
    },
    setPassword() {
        httpService.put('/profile', { password: this.rpassword })
        .then( res => {
            this.message = res.data
            this.showChanged = true
        })
        .catch( err => {
            this.message = err
            this.showError = true
        })
    },
    checkPassword() {
        if (this.password.length === 0) {
            return true
        } else {
            if (this.npassword.length > 8 ) {
                if (this.rpassword === this.npassword) {
                    return false
                }
            }
            return true
        }
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/variables';

    .v-app-bar {
        padding: 10px 0 10px 0;
        height: $header_auth_size_mobile !important;
        box-shadow: none !important;
        .v-toolbar__content {
        height: 100% !important;
        .header__points {
            margin: 0;
            font-size: 15px;
            color: $primary-color;
            font-weight: 500;
            margin-right: 50px;
            &-icon {
            font-size: 15px;
            margin-right: 5px;
            vertical-align: baseline;
            color: white;
            background: linear-gradient(to right, $primary_color 50%, black 50%);
            border-radius: 50%;
            }
        }
        .header__text {
            margin: 0;
            font-size: 15px;
            font-weight: 400;
            color: black;
            margin-right: 25px;
            span {
            color: $primary_color;
            }
        }
        .header__signout {
            margin: 0;
            font-size: 15px;
            font-weight: 400;
            color: black;
            margin-right: 25px;
            cursor: pointer;
            &:hover {
            border-bottom: 1px solid black;
            }
            &-icon {
            font-size: 18px;
            color: black;
            vertical-align: text-bottom;
            }
        }
        }
    }

    .home {
        margin-top: $header_auth_size_mobile;
        color: $text_color;
        background-color:white;
        width: 100%;
        font-weight: 400;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 960px) { 
            margin-top: $header_auth_size_desktop;
        }
        &__cupones {
            margin-bottom:40px;
        }
        &__password {
            max-width:1100px;
        }
        &__footer {
            margin-top:20px;
            background-color:#109bc5;
            min-height: 300px;
        }
        &__copyright {
            background-color:#11abd6;
            min-height: 50px;
            &__container {
                color:white;
                margin: 0 auto;
                max-width:1100px;
                padding-top:15px;
                font-size:85%;
            }
        }
        &__banner {
            background-color: $primary-color;
            min-height: 230px;
            padding: 20px;
            color: white;
            text-align: center;
            font-size: 22px;
            display: flex;
            line-height: 28px;
            @media (min-width: 960px) { 
                font-size: 26px;
                line-height: 32px;
            }
            &__text {
                margin: auto auto 30px auto;
                font-weight: bold;
                &-client {
                    color: black;
                }
                &-subtext {
                    font-weight: 400;
                }
            }
        }
        &__points, &__exchange {
            background-color: white;
            border-radius: 10px;
            margin: 20px auto;
            padding: 20px;
            text-align: center;
            color: black;
            max-width: 400px;
            @media (min-width: 600px) {
                margin: 60px 20px 60px 20px;
                padding: 30px;
            }
        }
        &__coupon {            
            border-radius: 10px;
            margin: 5px auto;
            padding: 5px;
            margin-bottom: 40px;
            text-align: center;
            color: black;
            max-width: 600px;
            @media (min-width: 600px) {                
                padding: 30px;
            }
            &-icon {
                color: black;                
                border-radius: 50%;
                font-size: 40px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                font-weight: bold;
                font-size: 13px;
                margin-bottom: 0;
            }
            &-coupon {
                font-size: 30px;
                color: $primary-color;
                padding:5px;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: black;
                margin-bottom: 0;
                font-size: 12px;
            }               
        }
        &__points {
            &-icon {
                color: white;
                background-color: black;
                border-radius: 50%;
                font-size: 15px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                margin-bottom: 0;
            }
            &-points {
                font-size: 30px;
                color: $primary-color;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: $primary-color;
                margin-bottom: 0;
            }
        }
        &__exchange {
            &-title {
                font-weight: bold;
                margin-bottom: 6px;
            }
            &-subtitle {
                color: $text_color;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 20px;
            }
            &-button {
                text-transform: none;
                padding: 20px 15px !important;
            }
        }
        &__personal {
            background-color: white;
            padding: 60px 20px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 30px;
            }
            &__cards {
                @media (min-width: 600px) {
                    display: flex;
                }
                &__card {
                    border: 2px solid $primary-color;
                    display: flex;
                    margin: 10px 20px;
                    padding: 15px;
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.05);
                    }
                    &-icon {
                        color: $primary-color;
                        margin-right: 8px;
                    }
                    &-text {
                        color: $text-color;
                        font-size: 14px;
                        margin: auto;
                    }
                }
            }
        }
        &__info {
            background-color: white;
            max-width: 1100px;            
            margin: 0 auto;
            padding: 30px 10px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 10px;
            }
            &-subtitle {
                color: $text-color;
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                max-width: 700px;
                margin: auto;
                padding-bottom: 50px;
            }
            &__cards {
                display: flex;
                &__card {
                    position: relative;
                    max-width: 80%;
                    padding: 10;
                    line-height: 0;
                    img {
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        border-radius: 50%;
                    }
                    &-text {
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        text-align: center;
                        color: white;
                    }
                    &-overlay {
                        opacity: 0.5;
                        background-color: #333333;
                        position: absolute;
                        border-radius: 50%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
</style>
